import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import PageItem from "../components/pageItem"
import SEO from "../components/seo"
import Img from "gatsby-image"

const SecondPage = (props) => (
  <Layout pageInfo={{ pageName: "Aether" }}>
    <SEO title="Aether" />
    {/* <h1>Ather</h1> */}
    {/* <p>Welcome to page 2</p> */}
    {/* <Link to="/">Go back to the homepage</Link> */}
    <div id="content" className="pages-placeholder  horizontal-scroll-wrapper" 
    style={{width: `3100vw`, background:"#A1CECB"}}
    >
      {/* <div className="pages"> */}

        <PageItem  >
          <Img fluid={props.data.aether01.childImageSharp.fluid} />
        </PageItem>
        <PageItem  ><Img fluid={props.data.aether02.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aether03.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aether04.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aether05.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aether06.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aether07.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aether08.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aether09.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aether10.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aether11.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aether12.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aether13.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aether14.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aether15.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aether16.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aether17.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aether18.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aether19.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aether20.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aether21.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aether22.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aether23.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aether24.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aether25.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aether26.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aether27.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aether28.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aether29.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.aether30.childImageSharp.fluid} /></PageItem>
        {/* <PageItem  ><Img fluid={props.data.aether31.childImageSharp.fluid} /></PageItem> */}



{/* <div>item 1</div>
  <div>item 2</div>
  <div>item 3</div>
  <div>item 4</div>
  <div>item 5</div>
  <div>item 6</div>
  <div>item 7</div>
  <div>item 8</div> */}










        {/* <div className="page 01">page01</div>
      <div className="page 02">page02</div> */}
      {/* </div> */}
    </div>
  </Layout>
)

export default SecondPage

export const fluidImage = graphql`
fragment fluidImage on File {
  childImageSharp {
    fluid(maxWidth: 1000) {
      ...GatsbyImageSharpFluid
    }
  }
}
`;

export const pageQuery = graphql`
  query {
    aether01: file(relativePath: { eq: "aether_01.jpg" }) {
      ...fluidImage
    }
    aether02: file(relativePath: { eq: "aether_02.jpg" }) {
      ...fluidImage
    }
    aether03: file(relativePath: { eq: "aether_03.jpg" }) {
      ...fluidImage
    }
    aether04: file(relativePath: { eq: "aether_04.jpg" }) {
      ...fluidImage
    }
    aether05: file(relativePath: { eq: "aether_05.jpg" }) {
      ...fluidImage
    }
    aether06: file(relativePath: { eq: "aether_06.jpg" }) {
      ...fluidImage
    }
    aether07: file(relativePath: { eq: "aether_07.jpg" }) {
      ...fluidImage
    }
    aether08: file(relativePath: { eq: "aether_08.jpg" }) {
      ...fluidImage
    }
    aether09: file(relativePath: { eq: "aether_09.jpg" }) {
      ...fluidImage
    }
    aether10: file(relativePath: { eq: "aether_10.jpg" }) {
      ...fluidImage
    }
    aether11: file(relativePath: { eq: "aether_11.jpg" }) {
      ...fluidImage
    }
    aether12: file(relativePath: { eq: "aether_12.jpg" }) {
      ...fluidImage
    }
    aether13: file(relativePath: { eq: "aether_13.jpg" }) {
      ...fluidImage
    }
    aether14: file(relativePath: { eq: "aether_14.jpg" }) {
      ...fluidImage
    }
    
    aether15: file(relativePath: { eq: "aether_15.jpg" }) {
      ...fluidImage
    }
    aether16: file(relativePath: { eq: "aether_16.jpg" }) {
      ...fluidImage
    }
    aether17: file(relativePath: { eq: "aether_17.jpg" }) {
      ...fluidImage
    }
    aether18: file(relativePath: { eq: "aether_18.jpg" }) {
      ...fluidImage
    }
    aether19: file(relativePath: { eq: "aether_19.jpg" }) {
      ...fluidImage
    }
    aether20: file(relativePath: { eq: "aether_20.jpg" }) {
      ...fluidImage
    }
    aether21: file(relativePath: { eq: "aether_21.jpg" }) {
      ...fluidImage
    }
    aether22: file(relativePath: { eq: "aether_22.jpg" }) {
      ...fluidImage
    }
    aether23: file(relativePath: { eq: "aether_23.jpg" }) {
      ...fluidImage
    }
    aether24: file(relativePath: { eq: "aether_24.jpg" }) {
      ...fluidImage
    }
    aether25: file(relativePath: { eq: "aether_25.jpg" }) {
      ...fluidImage
    }
    aether26: file(relativePath: { eq: "aether_26.jpg" }) {
      ...fluidImage
    }
    aether27: file(relativePath: { eq: "aether_27.jpg" }) {
      ...fluidImage
    }
    aether28: file(relativePath: { eq: "aether_28.jpg" }) {
      ...fluidImage
    }
    aether29: file(relativePath: { eq: "aether_29.jpg" }) {
      ...fluidImage
    }
    aether30: file(relativePath: { eq: "aether_30.jpg" }) {
      ...fluidImage
    }
    aether31: file(relativePath: { eq: "aether_31.jpg" }) {
      ...fluidImage
    }
  }
`